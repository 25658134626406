import { instance } from "../../lib/api";
import Constants from "../../utils/CommonConstants";
import {
  siteTakeoverSlotsParser,
  siteTakeoverDedicatedApiParser,
  getEditSlotData,
  mapCityListData,
  mapSellerListData,
} from "./siteTakeoverUtils";

export const RESET_ST_FORM_DATA = "RESET_ST_FORM_DATA";
export const SET_ST_FORM_DATA = "SET_ST_FORM_DATA";
export const SET_ST_ALL_FORM_DATA = "SET_ST_ALL_FORM_DATA";
export const RESET_ST_LIST = "RESET_ST_LIST";
export const SET_ST_LIST = "SET_ST_LIST";
export const UPDATE_ST_DATA = "UPDATE_ST_DATA";
export const ALL_CITY_LIST = "ALL_CITY_LIST";
export const BUILDER_LIST = "BUILDER_LIST";
export const SELLER_LIST = "SELLER_LIST";
export const SET_SITETAKEOVER_DEDICATED_DATA = "SET_SITETAKEOVER_DEDICATED_DATA";

export const resetFormData = () => (dispatch) =>
  dispatch({ type: RESET_ST_FORM_DATA });
export const setFormData = (section, data, index) => (dispatch) =>
  dispatch({
    type: SET_ST_FORM_DATA,
    payload: { section, data, index },
  });

export const getBuilders = (text) => {
  text = text.trim();
  const url = `${process.env.DOMAINS.SEARCH}api/v1/search/suggest?property_category=residential&service=buy&string=${text}&type=developer`;
  return (dispatch) => {
    if (!text) return;
    instance({
      url,
      method: "GET",
    })
      .then((response) => {
        dispatch({
          type: BUILDER_LIST,
          builders: response.data.data.results,
        });
      })
      .catch(() => {
        dispatch({
          type: "API_FAIL",
        });
      });
  };
};

export const createSlot = (data) => (dispatch) => {
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/create-builder-slots`;
  return instance({
    url,
    method: "POST",
    data,
    withCredentials: true,
  })
    .then((response) => {
      let { data: { data: { message: msg } = {} } = {} } = response;
      dispatch({
        type: Constants.SNACKBAR_NOTIFICATION,
        msg: msg,
      });
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const updateSlot = (data) => (dispatch) => {
  const { products: { 74: { slot_id: slotId } = {} } = {} } = data;
  const url = `${process.env.DOMAINS.ADVERT}/api/v0/slots/update/${slotId}`;
  return instance({
    url,
    method: "PUT",
    data: getEditSlotData(data),
    withCredentials: true,
  })
    .then((response) => {
      let { data: { data: { message: msg } = {} } = {} } = response;
      dispatch({
        type: Constants.SNACKBAR_NOTIFICATION,
        msg: msg,
      });
    })
    .catch((err) => {
      const errMsg = Constants.getErrorMessage(err);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

// http://ap.internal.staging.housing.com/api/v0/slots/${slotId} - GET
export const getSiteTakeoverData = (slotId) => (dispatch) => {
  const url = `/sapna/v1/housing/helper/8?slot_id=${slotId}`;
  return instance({
    url,
    method: "GET",
  })
    .then(({ data: { data = {} } = {} }) => {
      dispatch({
        type: SET_SITETAKEOVER_DEDICATED_DATA,
        payload: siteTakeoverDedicatedApiParser(data),
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getList = (pageNo, totalPages) => (dispatch) => {
  const projectIdsUrl = `${process.env.DOMAINS.AP}products-mapping`;
  return instance({
    url: projectIdsUrl,
    method: "GET",
  })
    .then(({ data }) => {
      const slotId = data["Site Takeover - New"];
      const newProjectsUrl = `${process.env.DOMAINS.ADVERT}products/get-all-packages?row_per_page=10&current_page_no=${pageNo}&promoted_entity_type_id=2&slot_status_id=0&placeholder_ids=${slotId}`;
      const buildersUrl = `${process.env.DOMAINS.ADVERT}products/get-all-packages?row_per_page=10&current_page_no=${pageNo}&promoted_entity_type_id=3&slot_status_id=0&placeholder_ids=${slotId}`;
      return Promise.all([
        instance({
          url: newProjectsUrl,
          withCredentials: true,
          method: "GET",
        }),
        instance({
          url: buildersUrl,
          withCredentials: true,
          method: "GET",
        }),
      ]);
    })
    .then(
      ([
        { data: { data: newProjectsData = [] } = {} },
        { data: { data: builderData = [] } = {} },
      ]) => {
        const newProjectSlots = newProjectsData.results.length
          ? newProjectsData.results.map((item) => ({
              ...item,
              promoted_entity_type: "New Projects",
            }))
          : [];
        const builderSlots = builderData.results.length
          ? builderData.results.map((item) => ({
              ...item,
              promoted_entity_type: "Builder",
            }))
          : [];
        dispatch({
          type: SET_ST_LIST,
          payload: {
            results: siteTakeoverSlotsParser([...newProjectSlots, ...builderSlots]),
            hasNextPage:
              pageNo === totalPages
                ? newProjectsData.next_page_exist || builderData.next_page_exist
                : false,
            totalPages,
          },
        });
      }
    )
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const resetList = () => (dispatch) => dispatch({ type: RESET_ST_LIST });

export const getAllCitiesList = () => {
  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/top/cities/list`;
  return new Promise((resolve, reject) => {
    instance({
      url,
      method: "GET",
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCities = () => (dispatch) => {
  const url = `${process.env.DOMAINS.REGIONS}api/v1/polygon/top/cities/list`;
  return instance({
    url,
    method: "GET",
  })
    .then((response) => {
      let data = mapCityListData(response.data);
      dispatch({
        type: ALL_CITY_LIST,
        allCities: data,
      });
    })
    .catch((error) => {
      const errMsg = Constants.getErrorMessage(error);
      dispatch({
        type: Constants.API_FAIL,
        errMsg: errMsg,
      });
    });
};

export const getSellers = (projectId) => {
  const baseUrl = `${process.env.DOMAINS.VENUS}api/v7/new-projects/${projectId}/webapp`;
  const queryParams = new URLSearchParams({
    version: "v3",
    slice_keys: JSON.stringify(["sellers_info", "info_level"]),
  }).toString();

  const url = `${baseUrl}?${queryParams}`;
  return (dispatch) => {
    if (!projectId) return;
    instance({
      url,
      method: "GET",
    })
      .then((response) => {
        let data = mapSellerListData(response?.data?.data ?? {});
        dispatch({
          type: SELLER_LIST,
          sellers: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const deleteImage = (sectionData, attrId, tagId) => (dispatch) => {
  const {
    card_id: cardId,
    priority,
    image: { absolute_url: absoluteUrl },
  } = sectionData;
  const cardID = cardId ? cardId.toString() : null;
  getImagesFromImageService(cardId, true)
    .then(({ data = [] }) => {
      let extension = "/cover.jpg"; //priority should be 1/6/7/8 for banner image, 2/3/4/5 for cover image and 9/10 for logo image
      let tag_ids = "1282"; //tag id to be passed will be 1445 for banner image i.e. priority 1/6/7/8, 1282 for cover image i.e. priority 2/3/4/5 and 1281 for logo image i.e. priority 9/10
      if ([1, 6, 7, 8].includes(priority) || tagId === "1445") {
        extension = "/banner.jpg";
        tag_ids = "1445";
      } else if (priority === 9 || priority === 10) {
        extension = "/logo.jpg";
        tag_ids = "1281";
      }
      if (data.length) {
        const imageInfo =
          data.find((image) => {
            const arr = image.absolute_url.split("/");
            return (
              arr
                .slice(0, arr.length - 1)
                .join("/")
                .concat(extension) === absoluteUrl
            );
          }) || {};
        const url = "/sapna/v1/housing/helper/18";
        const apiData = {
          housing_file: {
            attr_id: attrId,
            extension: imageInfo.extension || "jpg",
            authenticity_token: process.env.IMAGE_SERVICE_AUTH_TOKEN,
            priority,
            id: imageInfo.file_id,
            name: imageInfo.name,
            obj_id: cardID,
            tag_ids,
            upload_source: "desktop",
            caption: imageInfo.caption,
          },
        };
        return instance({
          url,
          method: "POST",
          data: apiData,
        })
          .then((response) => {
            let { data: { data: { message: msg } = {} } = {} } = response;
            dispatch({
              type: Constants.SNACKBAR_NOTIFICATION,
              msg: msg,
            });
          })
          .catch((err) => {
            dispatch({
              type: Constants.API_FAIL,
              errMsg: Constants.getErrorMessage(err),
            });
          });
      }
    })
    .catch((err) => {
      dispatch({
        type: Constants.API_FAIL,
        errMsg: Constants.getErrorMessage(err),
      });
    });
};

// http://images.internal.staging.housing.com/uploader/create_file
export const uploadImageToImageService =
  ({ tagId, tagType }) =>
  (data, sectionData) => {
    const { card_id, priority } = sectionData;
    const cardId = card_id ? card_id.toString() : null;
    const url = "/sapna/v1/housing/helper/10";
    const { attr_id, extension, md5, name } = data;
    let tag_ids = tagId || "1282"; //tag id to be passed will be 1445 for banner image i.e. priority 1/6/7/8, 1282 for cover image i.e. priority 2/3/4/5 and 1281 for logo image i.e. priority 9/10
    if ([1, 6, 7, 8].includes(priority)) {
      if (tagType === "stnew") tag_ids = "1545";
      else tag_ids = "1445";
    } else if (priority === 9 || priority === 10) {
      tag_ids = "1281";
    }
    const apiData = {
      upload_source: "web",
      re_upload: "false",
      authenticity_token: process.env.IMAGE_SERVICE_AUTH_TOKEN,
      housing_file: {
        attr_id,
        extension: extension || "jpg",
        md5,
        name,
        is_private: false,
        obj_id: cardId, //card id
        status: "new",
        tag_ids,
      },
    };
    return instance({
      url,
      method: "POST",
      data: apiData,
    })
      .then(() => {
        return getImagesFromImageService(card_id, false);
      })
      .catch(({ response: { data: { errors } = {} } = {} }) => {
        if (errors === "Tags Tags are compulsory") {
          return getImagesFromImageService(card_id, false);
        } else {
          return Promise.reject(errors);
        }
      });
  };

// http://images.internal.staging.housing.com/api/files/list
export const getImagesFromImageService = (objId, returnPromise) => {
  const url = `/sapna/v1/housing/helper/11?platform=desktop&source_name=WEB&housing_app_name=HousingAds&resource_name=SiteTakeOver-New&attr_name=images&obj_id=${objId}`;
  if (returnPromise) {
    return new Promise((resolve, reject) => {
      instance({
        url,
        method: "GET",
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  return instance({
    url,
    method: "GET",
  }).then(({ data: { data = {} } = {} }) => {
    return data.map(({ absolute_url: absoluteUrl }) =>
      absoluteUrl.replace("version", "original")
    );
  });
};

import {
  PACKAGE_FAMILY_DETAILS_IN_PROGRESS,
  PACKAGE_FAMILY,
  PACKAGE_FAMILY_DETAILS_FAIL,
  GOT_PACKAGES,
  GOT_REGIONS,
  ALLOWED_LOCALITY,
  HOUSING_LOCALITY,
  RESET_HOUSING_LOCALITY,
  HOUSING_SUBSCRIPTION_REGIONS,
  PRODUCT_PRICE,
  PACKAGE_ADDED,
  RESET_PACKAGE,
  MAKAAN_CITY_TIER_MAP,
  UPDATED_ACTIVATION_PARAMETERS,
  PRODUCT_ACTIVATION_PARAMETERS_UPDATE_IN_PROGRESS,
  PRODUCT_PRICE_RESET,
  ALL_PRODUCTS,
  COMPLIMENTARY_PRODUCTS_LIST,
  SET_PARENT_COMPLIMENTARY_PRODUCT,
  SET_COMPLIMENTARY_PRODUCT_DETAILS,
  COMPLIMENTARY_PRODUCTS_LIST_ERROR,
  SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR,
  ADD_COMPLIMENTARY_PRODUCT_SUCCESS,
  ADD_COMPLIMENTARY_PRODUCT_ERROR,
  ADD_COMPLIMENTARY_PRODUCT_IN_PROGRESS,
  STOP_COMPLIMENTARY_PRODUCT_SUCCESS,
  STOP_COMPLIMENTARY_PRODUCT_ERROR,
  STOP_COMPLIMENTARY_PRODUCT_IN_PROGRESS,
  GET_EVENT_PRODUCT_CAPPING,
  RESET_EVENT_PRODUCT_CAPPING,
  PACKAGE_ADDITION_IN_PROGRESS,
} from "../actions/product.actions";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PACKAGE_FAMILY_DETAILS_IN_PROGRESS:
      return {
        ...state,
        packageFamilyDetailsInProgess: action.payload,
      };
    case PACKAGE_ADDITION_IN_PROGRESS:
      return {
        ...state,
        packageAdditionInProgress: action.payload,
      };
    case PACKAGE_FAMILY:
      return {
        ...state,
        packageFamily: action.payload,
        packageFamilyDetailsInProgess: false,
      };
    case PACKAGE_FAMILY_DETAILS_FAIL:
      return {
        ...state,
        snackbar: {
          state: true,
          type: "error",
          duration: action.duration || null,
          message: action.message || "Something went wrong!",
        },
        packageFamilyDetailsInProgess: action.payload,
      };
    case GOT_PACKAGES:
      return {
        ...state,
        packageMap: {
          ...state.packageMap,
          [action.payload.familyId]: action.payload.data,
        },
      };
    case GOT_REGIONS:
      return {
        ...state,
        allRegion: action.payload,
        regionMap: action.regionMap,
      };
    case ALLOWED_LOCALITY:
      return {
        ...state,
        allowedLocality: action.payload,
      };
    case HOUSING_LOCALITY:
      return {
        ...state,
        housingLocality: action.payload,
      };
    case RESET_HOUSING_LOCALITY:
      return {
        ...state,
        housingLocality: [],
      };
    case HOUSING_SUBSCRIPTION_REGIONS:
      return {
        ...state,
        housingSubscriptionRegions: action.payload,
        housingSubscriptionRegionMap: action.housingSubscriptionRegionMap,
      };
    case PRODUCT_PRICE:
      return {
        ...state,
        productCurrentPrice: action.payload,
      };
    case PACKAGE_ADDED:
      return {
        ...state,
        newPackage: action.payload,
        snackbar: {
          state: true,
          message: "New package added!",
          type: "success",
        },
      };
    case RESET_PACKAGE:
      return {
        ...state,
        newPackage: null,
      };
    case MAKAAN_CITY_TIER_MAP:
      return {
        ...state,
        makaanCityTierMap: action.makaanCityTierMap,
      };
    case UPDATED_ACTIVATION_PARAMETERS:
      return {
        ...state,
        updatedActivationParameters: action.payload,
        areParamsEditedSuccessfully: action.payload.status,
        updatedActivationParametersInProgress: false,
        snackbar: {
          state: true,
          message: action.payload.msgText,
          type: action.payload.status ? "success" : "error",
        },
      };
    case PRODUCT_ACTIVATION_PARAMETERS_UPDATE_IN_PROGRESS:
      return {
        ...state,
        updatedActivationParametersInProgress: action.payload,
      };
    case PRODUCT_PRICE_RESET:
      return {
        ...state,
        productCurrentPrice: null,
      };
    case ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload,
      };
    case COMPLIMENTARY_PRODUCTS_LIST:
      return {
        ...state,
        complimentaryProductsList: action.payload,
        complimentaryProductsError: null,
      };
    case SET_PARENT_COMPLIMENTARY_PRODUCT:
      return {
        ...state,
        parentComplimentaryProduct: action.payload,
        deactivateComplimentaryProductMap: {},
      };
    case SET_COMPLIMENTARY_PRODUCT_DETAILS:
      return {
        ...state,
        complimentaryProductDetails: action.payload,
        complimentaryProductsError: null,
      };
    case COMPLIMENTARY_PRODUCTS_LIST_ERROR:
      return {
        ...state,
        complimentaryProductsListError: action.payload,
      };
    case SET_COMPLIMENTARY_PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        complimentaryProductDetailsError: action.payload,
      };
    case ADD_COMPLIMENTARY_PRODUCT_SUCCESS:
      return {
        ...state,
        addComplimentaryProductSuccess: action.payload,
        addComplimentaryProductError: null,
        addComplimentaryProductInProgress: null,
      };
    case ADD_COMPLIMENTARY_PRODUCT_ERROR:
      return {
        ...state,
        addComplimentaryProductError: action.payload,
        addComplimentaryProductSuccess: null,
        addComplimentaryProductInProgress: null,
      };
    case ADD_COMPLIMENTARY_PRODUCT_IN_PROGRESS:
      return {
        ...state,
        addComplimentaryProductInProgress: action.payload,
      };

    case STOP_COMPLIMENTARY_PRODUCT_SUCCESS:
      return {
        ...state,
        deactivateComplimentaryProductMap: {
          ...state.deactivateComplimentaryProductMap,
          [action.id]: {
            success: action.payload,
            inProgress: false,
            error: false,
          },
        },
      };
    case STOP_COMPLIMENTARY_PRODUCT_ERROR:
      return {
        ...state,
        deactivateComplimentaryProductMap: {
          ...state.deactivateComplimentaryProductMap,
          [action.id]: {
            success: false,
            inProgress: false,
            error: action.payload,
          },
        },
      };
    case STOP_COMPLIMENTARY_PRODUCT_IN_PROGRESS:
      return {
        ...state,
        deactivateComplimentaryProductMap: {
          ...state.deactivateComplimentaryProductMap,
          [action.id]: {
            success: false,
            inProgress: action.payload,
            error: false,
          },
        },
      };
    case GET_EVENT_PRODUCT_CAPPING:
      return {
        ...state,
        eventProductMappings: action.payload,
      };
    case RESET_EVENT_PRODUCT_CAPPING:
      return {
        ...state,
        eventProductMappings: undefined,
      };
  }
};

export default reducer;

import { createFiqlFilter, parseDashboardResponse } from "../../lib/helper";

import Constants from "../../utils/CommonConstants";
import { instance } from "../../lib/api";
import { gaTrackingFn } from "../../tracking/tracking";
import ownerCallStatus from "../../tracking/callStatus";
import constant from "../../utils/CommonConstants";
import { batch } from "react-redux";

export const OWNER_DASHBOARD_STATS = "OWNER_DASHBOARD_STATS";
export const GET_OWNER_FIELD_LIST = "GET_OWNER_FIELD_LIST";
export const CALLING_CUSTOMER = "CALLING_CUSTOMER";
export const OWNER_PACKAGES = "OWNER_PACKAGES";
export const OWNER_COMMERCIAL_PACKAGES = "OWNER_COMMERCIAL_PACKAGES";
export const SHARE_LINK = "SHARE_LINK";
export const SEND_PACKAGE_INFO = "SEND_PACKAGE_INFO";
export const CALLING_QUEUE = "CALLING_QUEUE";
export const RESET_CALLING_CUSTOMER = "RESET_CALLING_CUSTOMER";
export const CALLING_QUEUE_COUNT = "CALLING_QUEUE_COUNT";
export const AGENT_STATUS_UPDATE = "AGENT_STATUS_UPDATE";
export const CALLING_QUEUE_CASE = "CALLING_QUEUE_CASE";
export const RESET_OWNER_CALLING_STATUS = "RESET_OWNER_CALLING_STATUS";
export const PAYMENT_DONE_ACTIVATION_COUNT = "PAYMENT_DONE_ACTIVATION_COUNT";
export const PAYMENT_DONE_ACTIVATION_DATA = "PAYMENT_DONE_ACTIVATION_DATA";
export const PACKAGE_HISTORY = "PACKAGE_HISTORY";
export const CHANGE_AGENT_STATUS_API = "CHANGE_AGENT_STATUS_API";
export const AGENT_NUMBER_INFO = "AGENT_NUMBER_INFO";
export const CALL_HANGUP_SUCCESS = "CALL_HANGUP_SUCCESS";
export const CALL_HANGUP_FAIL = "CALL_HANGUP_FAIL";
export const RESET_CALL_HANGUP = "RESET_CALL_HANGUP";
export const ADD_ON_OWNER_PACKAGES = "ADD_ON_OWNER_PACKAGES";

const addToFIQL = (fiqlFilter, key, value) => {
  const equal = {};
  equal[key] = value;
  fiqlFilter.push({
    equal,
  });
  return fiqlFilter;
};

const getAddOnsOnwer = (packagesObj = {}) => {
  const { results: allPackages = {}, comboMap: { addOn: addOnObject = {} } = {} } =
    packagesObj;
  const addOnPackages = {};

  for (const [type, packages] of Object.entries(allPackages)) {
    const TYPE_NAME = type.toUpperCase();
    if (!addOnPackages[TYPE_NAME]) {
      addOnPackages[TYPE_NAME] = {};
    }

    for (const [packageName, packageDetails] of Object.entries(packages)) {
      const { packageId, actualPrice, markupPrice, discountedPrice, promotedDays } =
        packageDetails || {};

      if (addOnObject[packageId]) {
        addOnPackages[TYPE_NAME][packageName] = {
          packageId,
          actualPrice,
          markupPrice,
          discountedPrice,
          promotedDays,
          name: `${type} - ${packageName}`,
          allowedBasePackages: addOnObject[packageId],
        };
      }
    }
  }

  return addOnPackages;
};

const addDateRangeFilter = (fiqlFilter = [], key, dateRange) => {
  const dates = {};
  dates.from = dateRange.start ? dateRange.start.valueOf() : 0;
  dates.to = dateRange.end ? dateRange.end.valueOf() : new Date().getTime();
  const range = {};
  range[key] = dates;
  fiqlFilter.push({ range });
  return fiqlFilter;
};

const setExtraFIQLFilters = (filters) => {
  let fiqlFilter = [];
  let values;
  if (filters) {
    if (filters.statusValues?.length) {
      values = filters.statusValues.map((status) => {
        return filters.statusMapping[status];
      });
      fiqlFilter = addToFIQL(fiqlFilter, "statusId", values);
    }
    if (filters.orderIds?.length) {
      fiqlFilter = addToFIQL(fiqlFilter, "opportunityId", filters.orderIds);
    }
    if (filters.selectedAgents && Object.keys(filters.selectedAgents).length) {
      values = Object.keys(filters.selectedAgents).map((agent) => {
        return filters.selectedAgents[agent].id;
      });
      fiqlFilter = addToFIQL(fiqlFilter, "agentId", values);
    }
    if (filters.selectedCities && Object.keys(filters.selectedCities).length) {
      values = Object.keys(filters.selectedCities).map((city) => {
        return filters.selectedCities[city];
      });
      fiqlFilter = addToFIQL(fiqlFilter, "cityIds", values);
    }
    if (filters.opportunityName) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "opportunityName",
        encodeURIComponent(filters.opportunityName)
      );
    }
    if (filters.accountName) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "accountName",
        encodeURIComponent(filters.accountName)
      );
    }
    if (filters.contactNumber) {
      fiqlFilter = addToFIQL(
        fiqlFilter,
        "phoneNumbers",
        encodeURIComponent(filters.contactNumber)
      );
    }
    if (filters.accountIds?.length) {
      fiqlFilter = addToFIQL(fiqlFilter, "accountId", filters.accountIds);
    }
    if (filters.createdAt?.start || filters.createdAt?.end) {
      fiqlFilter = addDateRangeFilter(fiqlFilter, "createdAt", filters.createdAt);
    }
    if (filters.updatedAt?.start || filters.updatedAt?.end) {
      fiqlFilter = addDateRangeFilter(fiqlFilter, "updatedAt", filters.updatedAt);
    }
  }
  return fiqlFilter;
};
const getTeleSalesDashboardInstance = (isPost, { url, postUrl, strSelector }) => {
  if (isPost) {
    return instance({
      url: postUrl,
      method: "POST",
      data: strSelector,
      headers: {
        "Content-Type": "application/text",
      },
    });
  }

  return instance({
    url: url,
    method: "get",
  });
};

export const getTeleSalesDashboard = (
  isPost = false,
  filter,
  agentId = null,
  keys,
  forDashboard = true,
  oldFilters = null,
  isOwnerAgent = true,
  filters
) => {
  let fiqlFilter = setExtraFIQLFilters(filters);
  const selectorArr = [];
  const paging =
    oldFilters && oldFilters.paging ? oldFilters.paging : { start: 0, rows: 0 };
  keys.forEach((key) => {
    const keyFilter = filter[key];
    const filters = {};

    if (keyFilter.addAsFilter) {
      const fiqlFilter = createFiqlFilter(keyFilter, agentId, oldFilters, filters);
      if (fiqlFilter.and.length > 0) {
        filters.filters = fiqlFilter;
      }
    }
    filters.key = keyFilter.key;
    if (agentId && !keyFilter.useDiscountApprovingUserId) {
      filters.agentId = agentId;
    }

    filters.team = true;
    filters.paging = paging;
    if (keyFilter.caseSubTypeId) {
      filters.caseSubTypeId = keyFilter.caseSubTypeId;
    }
    if (keyFilter.sortKey) {
      filters.sort = keyFilter.sortKey;
    }

    selectorArr.push(filters);
  });
  if (
    fiqlFilter.length &&
    selectorArr[0] &&
    selectorArr[0].filters &&
    selectorArr[0].filters.and
  )
    selectorArr[0].filters.and = [...selectorArr[0].filters.and, ...fiqlFilter];
  const strSelector = JSON.stringify(selectorArr);

  const url = `/mystique/v1/soc/key-search?salesOpportunitySelectorsArray=${strSelector}`;
  const postUrl = "/mystique/v1/soc/post/key-search";

  return (dispatch) => {
    return getTeleSalesDashboardInstance(isPost, { url, postUrl, strSelector })
      .then((response) => {
        if (forDashboard) {
          dispatch({
            type: OWNER_DASHBOARD_STATS,
            payload: {
              filters: oldFilters,
              dashboard: parseDashboardResponse(
                response.data.data,
                keys,
                isOwnerAgent
              ),
            },
          });
        } else {
          dispatch({
            type: GET_OWNER_FIELD_LIST,
            data: response.data.data && response.data.data[0],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getOwnerCallingQueue = (
  start,
  rows,
  countOnly = false,
  queueType,
  statusIds = [],
  filterState = {}
) => {
  let url = `/mystique/v1/soc/owner-calling-queue?start=${start}&rows=${rows}`;
  const {
    selectedAgents,
    createdAt: { start: startDate, end: endDate } = {},
    ownerNumber,
    ownerName,
  } = filterState;
  if (queueType === "linkSent") {
    url = url.concat(
      `&statusIds=${statusIds.join(",")}&ownerOpportunityStageIds=98`
    );
    if (selectedAgents) {
      url = url.concat(`&agentIds=${selectedAgents}`);
    }
    if (startDate && endDate) {
      url = url.concat(`&dateFrom=${startDate}&dateTo=${endDate}`);
    }
    if (ownerNumber) {
      url = url.concat(`&ownerContacts=${ownerNumber}`);
    }
    if (ownerName) {
      url = url.concat(`&ownerNames=${ownerName}`);
    }
  }
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        if (countOnly) {
          dispatch({
            type: CALLING_QUEUE_COUNT,
            queueType,
            payload: response.data.totalCount,
          });
        } else {
          dispatch({
            type: CALLING_QUEUE,
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

/**
 * To Implement
 * Api call handling for call integration
 */

export const handleCall = (
  caseId,
  number,
  isBrokerAgent = false,
  isBuilderAgent = false
) => {
  let subType = "HousingOwner";
  if (isBrokerAgent) {
    subType = "Housing";
  } else if (isBuilderAgent) {
    subType = "HousingBuilder";
  }
  const url = `/mystique/v1/cases/${caseId}/call?type=SOCASE&custNumber=${number}&subType=${subType}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then(
        ({
          data: {
            data: { status, id, message },
          },
        }) => {
          dispatch({
            type: CALLING_CUSTOMER,
            payload: {
              status,
              id,
              message: message ? `${status} - ${message}` : null,
            },
          });
          if (!isBrokerAgent) {
            const { CALL_TRIGGERED, CALL_TRIGGERED_FAILURE } = ownerCallStatus;
            dispatch(
              gaTrackingFn(id ? CALL_TRIGGERED : CALL_TRIGGERED_FAILURE, {
                communication_id: id,
              })
            );
          }

          return id;
        }
      )
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        dispatch({
          type: RESET_CALLING_CUSTOMER,
        });
        !isBrokerAgent &&
          dispatch(gaTrackingFn(ownerCallStatus.CALL_TRIGGERED_FAILURE, {}));

        return err;
      });
  };
};

export const handleEndCall = (agentPhoneNumber) => {
  const HANGUP_CALL_URL = "kira/bspl/hangup";
  const queryParams = new URLSearchParams({
    agentPhone: agentPhoneNumber,
  });
  const url = `${HANGUP_CALL_URL}?${queryParams.toString()}`;

  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { message } = response.data;
        dispatch({
          type: CALL_HANGUP_SUCCESS,
          errMsg: message,
        });
      })
      .catch((err) => {
        dispatch({
          type: CALL_HANGUP_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

const paymentDoneActivationDetails = (
  userType = Constants.housingOwnerAgentRole,
  start = 0,
  rows = 0,
  team = true,
  userId
) => {
  let clientType =
    userType == constant.builderTelesalesAgentRole ? "BUILDER" : "BROKER";
  let url = `/sapna/v1/opportunity/get-owner-product-activation?clientType=${clientType}`;

  if (userType == Constants.housingOwnerAgentRole) {
    url = "/sapna/v1/opportunity/get-owner-product-activation";
  }
  const params = { userId };
  let productActivated = true;
  let data = [
    {
      team,
      paging: {
        start,
        rows,
      },
      productActivated,
    },
  ];
  productActivated = false;
  data.push({
    team,
    paging: {
      start,
      rows,
    },
    productActivated,
  });
  return instance({
    url,
    method: "post",
    data: data,
    params,
  });
};

export const populatePaymentDoneData = (
  userInfoRole,
  start,
  rows,
  team,
  productActivated,
  userId
) => {
  return (dispatch) => {
    paymentDoneActivationDetails(userInfoRole, start, rows, team, userId)
      .then(({ data: { data = [] } = {} }) => {
        dispatch({
          type: PAYMENT_DONE_ACTIVATION_DATA,
          payload: (productActivated ? data[0] : data[1]) || {},
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const getPaymentAndActivationData = (userType) => {
  return (dispatch) => {
    const keys = ["activationComplete", "activationPending"];

    paymentDoneActivationDetails(userType)
      .then((response) => {
        const { data } = response.data;
        for (let index = 0; index < 2; index++) {
          let { totalCount } = data[index];
          keys?.[index] &&
            dispatch({
              type: PAYMENT_DONE_ACTIVATION_COUNT,
              payload: {
                key: keys[index],
                totalCount,
              },
            });
        }
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};
export const getOwnerPackages = ({ profileUUID = "" }) => {
  const url = `/sapna/v5/package-price/owner?accountAgentType=${constant.accountAgentType}&profileUuid=${profileUUID}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        batch(() => {
          dispatch({
            type: OWNER_PACKAGES,
            payload: response.data.data.results,
          });
          dispatch({
            type: OWNER_COMMERCIAL_PACKAGES,
            payload: response.data.data.commercialResults,
          });
        });
        dispatch({
          type: OWNER_PACKAGES,
          payload: response.data.data.results,
        });

        const apiData = response?.data?.data || {};
        const addOnOwnerPackages = getAddOnsOnwer(apiData);
        dispatch({
          type: ADD_ON_OWNER_PACKAGES,
          payload: addOnOwnerPackages,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const shareLink = (data) => {
  const url = "/sapna/v4/opportunity";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: SHARE_LINK,
          payload: response.data.data,
          snackbar: response.data.data
            ? "Link shared successfully"
            : "Link sharing failed",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const sendOwnerPackageInfo = (data) => {
  const url = "/sapna/v2/packages/share-details";
  return (dispatch) => {
    return instance({
      url,
      method: "post",
      data,
    })
      .then((response) => {
        dispatch({
          type: SEND_PACKAGE_INFO,
          payload: response.data.data
            ? "Package Details shared successfully"
            : "Package Details sharing failed",
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      });
  };
};

export const changeAgentStatus = (newStatus) => {
  const url = `/sapphire/v1/agents/status?status=${newStatus}`;
  return (dispatch) => {
    dispatch({
      type: CHANGE_AGENT_STATUS_API,
      payload: true,
    });
    return instance({
      url,
      method: "put",
    })
      .then(() => {
        dispatch({
          type: AGENT_STATUS_UPDATE,
          payload: newStatus,
        });
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
      })
      .finally(() => {
        dispatch({
          type: CHANGE_AGENT_STATUS_API,
          payload: false,
        });
      });
  };
};

export const pullCase = (userInfoRole = Constants.housingOwnerAgentRole) => {
  let url = "/mystique/v1/soc/pull";
  if (userInfoRole == Constants.brokerTelesalesAgentRole) {
    url += "/broker";
  } else if (userInfoRole == Constants.builderTelesalesAgentRole) {
    url += "/builder";
  }
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const startOfTimer = new Date().getTime();
        dispatch({
          type: CALLING_QUEUE_CASE,
          payload: {
            pullCaseData: response.data.data,
            startOfTimer,
          },
        });
        dispatch({
          type: RESET_CALLING_CUSTOMER,
        });
        dispatch({
          type: RESET_CALL_HANGUP,
        });
        if (!response.data.data?.id) {
          dispatch({
            type: Constants.SNACKBAR_NOTIFICATION,
            snackBarType: "error",
            msg: "No opportunity available to pull",
          });
        }

        return response;
      })
      .catch((err) => {
        dispatch({
          type: Constants.API_FAIL,
          errMsg: Constants.getErrorMessage(err),
        });
        return err;
      });
  };
};

export const getAgentNumberInfo = (agentId, isBrokerTelesalesAgent) => {
  const AGENT_INFO_URL = "mystique/v1/agentsphone";
  const filters = `agentId==${agentId};sourceId==${
    isBrokerTelesalesAgent ? 31 : 18
  }`;
  const url = `${AGENT_INFO_URL}?filters=${filters}`;
  return (dispatch) => {
    return instance({
      url,
      method: "get",
    })
      .then((response) => {
        const { data: { data = [] } = [] } = response || {};
        dispatch({
          type: AGENT_NUMBER_INFO,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: constant.API_FAIL,
          errMsg: constant.getErrorMessage(err),
        });
      });
  };
};

export const reloadCase = (caseId, isPrioritisedPull = 1) => {
  const url = `/mystique/v1/soc/${caseId}`;
  return instance({
    url,
    data: { isPrioritisedPull },
    method: "put",
  });
};
export const clientInactiveBeforeCall = (caseId, readTimeData = {}) => {
  const url = `/mystique/v1/soc/${caseId}`;
  return instance({
    url,
    data: readTimeData,
    method: "put",
  });
};

export const getAgentStatus = () => {
  const url = `/sapphire/v1/agents/status`;
  return instance({
    url,
    method: "get",
  })
    .then((response) => {
      return response.data.data.status;
    })
    .catch(() => {});
};
export const getPullStatus = (caseId) => {
  const url = `/mystique/v1/soc/${caseId}`;
  return instance({
    url,
    method: "get",
  })
    .then((response) => {
      return response.data.data.isPrioritisedPull;
    })
    .catch(() => {});
};

export const resetCallingStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_OWNER_CALLING_STATUS,
    });
  };
};

export const getAgents = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      userInfo: { id },
    } = state;
    const url = `/madrox/app/v1/entity/child-list?userId=${id}&status=active&fromCache=false&selector={"fields":["name", "userId"]}`;
    return instance({
      url,
      method: "get",
    }).then((response) => {
      const { data: { data = [] } = {} } = response;
      const agents = data.reduce((acc, agent) => {
        return {
          ...acc,
          [agent.name]: agent,
        };
      }, {});
      dispatch({
        type: "AGENT_LIST",
        agents,
      });
    });
  };
};
const getReadableDate = (timestamp) => {
  return (timestamp && Constants.epochToDate(timestamp * 1000)) || "";
};

const parsePackageHistory = (data) => {
  const products = ["upcoming", "live", "expired"];
  let details = [];
  products.forEach((status) => {
    if (data && data[status]) {
      details = [
        ...details,
        ...data[status].reduce(
          (
            details,
            {
              package_name: name,
              products_name: products = [],
              start_datetime: startDate,
              end_datetime: endDate,
              slot_id: slotId,
              buy_leads_delivered: buyLeads,
              rent_leads_delivered: rentLeads,
              commercial_buy_leads_delivered: commercialBuyLeads = 0,
              commercial_rent_leads_delivered: commercialRentLeads = 0,
            }
          ) => [
            ...details,
            ...(products.length
              ? products.map((name) => ({
                  name,
                  status,
                  startDate: getReadableDate(startDate),
                  endDate: getReadableDate(endDate),
                  slotId,
                  buyLeads,
                  rentLeads,
                  commercialBuyLeads,
                  commercialRentLeads,
                }))
              : [
                  {
                    name,
                    status,
                    startDate: getReadableDate(startDate),
                    endDate: getReadableDate(endDate),
                    slotId,
                    buyLeads,
                    rentLeads,
                    commercialBuyLeads,
                    commercialRentLeads,
                  },
                ]),
          ],
          []
        ),
      ];
    }
  });
  return details;
};
export const getPackageHistory = (accountId) => {
  return (dispatch) => {
    const url = `/sapna/v1/housing/helper/20?profile_uuid=${accountId}`;
    return instance({
      url,
      method: "get",
    }).then((response) => {
      const { data: { data = {} } = {} } = response;
      dispatch({
        type: PACKAGE_HISTORY,
        payload: {
          packagesHistory: parsePackageHistory(data),
        },
      });
    });
  };
};

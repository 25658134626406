export const debounce = (func, wait = 300, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const range = (length = 0) => {
  return new Array(length).fill(0).map((v, idx) => idx + 1);
};

const millisecondsPerDay = 24 * 60 * 60 * 1000;
const getDateFromString = (string) => {
  string = string.split("-");
  return new Date(string[0], string[1] - 1, string[2]);
};

export const processSlotAvailabilityDetails = (slotDetails) => {
  const result = {};
  const polygonLevelDataList = Object.keys(slotDetails).map(
    (polygonId) => slotDetails[polygonId]
  );
  const productLevelDataList = polygonLevelDataList.reduce((result, productData) => {
    return result.concat(
      Object.keys(productData).map((productId) => productData[productId])
    );
  }, []);
  result.isAvailable = productLevelDataList.every((detail) => detail.available);
  if (result.isAvailable) {
    result.availabilityCount = Infinity;
    productLevelDataList.forEach((detail) => {
      if (
        detail.availability_count &&
        detail.availability_count < result.availabilityCount
      ) {
        result.availabilityCount = detail.availability_count;
      }
    });
  } else {
    result.isFutureAvailabe = productLevelDataList.every(
      (detail) => detail.availability_count || detail.expected
    );
  }
  if (result.isFutureAvailabe) {
    const productsAvailableLater = productLevelDataList
      .filter((detail) => detail.expected)
      .map((detail) => detail.expected);
    const maxValueForAvailability = productsAvailableLater.length;
    const minDate = {};
    const maxDate = {};
    productsAvailableLater.forEach((data) => {
      const firstStartDate = data[0].start_date;
      const firstStartDateObj = getDateFromString(firstStartDate);
      if (!minDate.dateValue || minDate.dateObj > firstStartDate) {
        minDate.dateValue = firstStartDate;
        minDate.dateObj = firstStartDateObj;
      }
      const lastStartDate = data[data.length - 1].start_date;
      const lastStartDateObj = getDateFromString(lastStartDate);
      if (!maxDate.dateValue || maxDate.dateObj < lastStartDate) {
        maxDate.dateValue = lastStartDate;
        maxDate.dateObj = lastStartDateObj;
      }
    });
    const numberOfDays =
      Math.round((maxDate.dateObj - minDate.dateObj) / millisecondsPerDay) + 1;
    const tempArray = new Array(numberOfDays + 1).fill(0);
    const dateArray = tempArray.slice();
    dateArray[numberOfDays] = "Available Onwards";

    productsAvailableLater.forEach((data) => {
      data.forEach((gap) => {
        const startDayOffset = Math.round(
          (getDateFromString(gap.start_date) - minDate.dateObj) / millisecondsPerDay
        );
        const endDayOffset =
          gap.end_date &&
          Math.round(
            (getDateFromString(gap.end_date) - minDate.dateObj) / millisecondsPerDay
          );

        tempArray[startDayOffset] += 1;
        dateArray[startDayOffset] = gap.start_date;
        if (endDayOffset) {
          tempArray[endDayOffset + 1] -= 1;
          dateArray[endDayOffset + 1] = gap.end_date;
        }
      });
    });
    for (let i = 1; i <= numberOfDays; i++) {
      tempArray[i] += tempArray[i - 1];
    }
    const tempResult = [];
    let range = {};
    for (let i = 0; i <= numberOfDays; i++) {
      if (tempArray[i] === maxValueForAvailability) {
        range.start = i;
        i++;
        while (tempArray[i] === maxValueForAvailability && i <= numberOfDays) {
          i++;
        }
        range.end = i - 1;
        tempResult.push(range);
        range = {};
      }
    }
    result.availabilityDates = tempResult.map((range) => ({
      start: dateArray[range.start],
      end: dateArray[range.end],
    }));
  }
  return result;
};

export const createFiqlFilter = (keyFilter, agentId, oldFilters, filters) => {
  const fiqlFilter = {
    and: [],
  };
  const dateFilters = ["fupTime", "createdAt", "assignedAt"];

  if (keyFilter.useDateFilter) {
    const { timeRange, applyTimeFilter } = getTimeRange(oldFilters);
    const setTimeRange = () => {
      const range = {};
      range[keyFilter.dateFilter] = timeRange;
      fiqlFilter.and.push({ range });
    };
    if (applyTimeFilter) {
      if (keyFilter.addBothRangeAndDateAsFilter) {
        setTimeRange();
        filters.timeRange = timeRange;
      } else if (dateFilters.includes(keyFilter.dateFilter)) {
        setTimeRange();
      } else {
        filters.timeRange = timeRange;
      }
    }
  }
  if (keyFilter.statusId) {
    const equal = {};
    equal.statusId = keyFilter.statusId;
    if (keyFilter.opportunityTypeId) {
      equal.opportunityTypeId = keyFilter.opportunityTypeId;
    }
    if (keyFilter.reasonId) {
      equal.reasonId = keyFilter.reasonId;
    }
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.ownerOpportunityStageId) {
    const equal = {};
    equal.ownerOpportunityStageId = keyFilter.ownerOpportunityStageId;
    if (keyFilter.opportunityTypeId) {
      equal.opportunityTypeId = keyFilter.opportunityTypeId;
    }
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.discountApproved) {
    const equal = {};
    equal.discountApproved = keyFilter.discountApproved;
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.useDiscountApprovingUserId && agentId) {
    const equal = {};
    equal.discountApprovingUserId = agentId;
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.opportunitySource) {
    const equal = {};
    equal.opportunitySource = keyFilter.opportunitySource;
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.opportunityCampaigns) {
    const equal = {};
    equal.opportunityCampaigns = keyFilter.opportunityCampaigns;
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.agentDesignationId) {
    const equal = {};
    equal.agentDesignationId = keyFilter.agentDesignationId;
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.approvalAuthority) {
    const equal = {};
    equal["approval.authority"] = [agentId];
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.approvalReviewers) {
    const equal = {};
    equal["approval.reviewers.reviewer"] = [agentId];
    fiqlFilter.and.push({ equal });
  }
  if (keyFilter.reviewStatus) {
    const equal = {};
    equal["approval.reviewers.0.status"] = keyFilter.reviewStatus;
    fiqlFilter.and.push({ equal });
  }
  return fiqlFilter;
};

export const parseDashboardResponse = (resp, keys, excludeLinksSent = false) => {
  const dashboardResp = {};
  for (let i = 0; i < resp.length; i++) {
    if (keys[i] === "linkSent" && excludeLinksSent) {
      continue;
    }
    dashboardResp[keys[i]] = resp[i] && resp[i].totalCount ? resp[i].totalCount : 0;
  }
  return dashboardResp;
};

const getTimeRange = (filters) => {
  if (!filters) return false;
  let applyTimeFilter = false;
  const timeRange = {};
  const { startDate, endDate } = filters;
  if (startDate) {
    timeRange.from = startDate.setHours(0, 0, 0, 0);
    applyTimeFilter = true;
  }
  if (endDate) {
    timeRange.to = endDate.setHours(23, 59, 59, 999);
    applyTimeFilter = true;
  }
  return { timeRange, applyTimeFilter };
};

export const addDays = ({
  startDate,
  daysToAdd,
  maxCurrentDateRequired = false,
}) => {
  const d = new Date(startDate);
  const today = new Date();
  d.setDate(startDate.getDate() + daysToAdd - 1);
  return maxCurrentDateRequired && d > today ? today : d;
};
